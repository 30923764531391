<template>
  <HandleDataForms v-bind:apiSource="mongo" v-bind:dataSource="'/'+database" title="Llamados - Antiguo" collection="clicktocall" templateTable="TableLlamadoForm" templateTableModal="TableLlamadoModal" v-bind:dataExcel="dataExcel" />
</template>

<script>
  import { computed } from 'vue'
  import { useStore } from 'vuex'
  import moment from 'moment'
  import HandleDataForms from '@/components/HandleDataForms.vue'

  export default {
    components: {
      HandleDataForms,
    },
    setup() {
      const store = useStore()
      const mongo = computed(() => store.getters.getMongoURL)
      const database = computed(() => store.getters.getDBDashboard)
      const startDate = computed(() =>
        moment()
          .startOf('week')
          .format('YYYY-MM-DD')
      )
      const endDate = computed(() => moment().format('YYYY-MM-DD'))

      let dataExcel = {
        fields: {
          fecha: 'Fecha',
          contactId: 'ID de Contacto',
          crm_user_id: 'ID de usuario CRM',
          nombre: 'Nombre',
          apellido_paterno: 'Apellido paterno',
          apellido_materno: 'Apellido materno',
          nacionalidad: { nombre: 'Nacionalidad' },
          tipo_documento: 'Tipo de  documento',
          rut: 'Nº de documento',
          pasaporte: 'Pasaporte',
          email: 'E-mail',
          celular_codigo: 'Código celular',
          celular_numero: 'Número celular',
          telefono_codigo: 'Código teléfono',
          telefono_numero: 'Número teléfono',
        },
        query: {
          collection: 'llamados',
          fecha_inicio: startDate.value + ' 00:00:00',
          fecha_fin: endDate.value + ' 23:59:59',
        },
      }

      return {
        mongo,
        dataExcel,
        database
      }
    },
  }
</script>
